// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey: "AIzaSyAxKOKyukLbYvqhUbfzgPEQszlHTw5MAHc",
    authDomain: "over4sj-2.firebaseapp.com",
    projectId: "over4sj-2",
    storageBucket: "over4sj-2.appspot.com",
    messagingSenderId: "222675231098",
    appId: "1:222675231098:web:bb14dff2a9c61ac7fa36c3",
    measurementId: "G-7ZW538HL48"
};


// Initialize Firebase

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };