import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import back from "./resources/back.png";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from './firebase';
import { v4 as uuidv4 } from 'uuid';

function App() {

  const [docId, setDocId] = useState(null);
  const [uid, setUid] = useState(null);

  useEffect(() => {
    setUid(uuidv4());

    const fireInvalidURLError = () => {
      Swal.fire({
        icon: "error",
        title: "正しいURLではありません",
        text: "OVER Z会場へお越しの上，画面の二次元コードをスキャンしてください．",
        showConfirmButton: false,
        allowOutsideClick: false
      });
    }

    const initJob = async () => {
      const url = new URL(window.location.href);
      const searchParams = url.searchParams;
      const key = searchParams.get("key");
      if (!key) {
        fireInvalidURLError();
        return;
      };
      const ref = doc(db, "electrolab", key);
      const docSnap = await getDoc(ref);
      if (!docSnap.exists() || (docSnap.data().initialized && docSnap.data().ended)) {
        fireInvalidURLError();
        return;
      }

      updateDoc(ref, {
        initialized: true,
        type: "LAB"
      });

      setDocId(docSnap.id);
    }
    initJob();
  }, []);

  useEffect(() => {
    const docUpdateEffect = async (data) => {
      console.log(data);
      const ref = doc(db, "electrolab", docId);
      await updateDoc(ref, data);
    }


    if (docId) {
      const intervalId = setInterval(() => {
        docUpdateEffect({
          updated: new Date()
        });
      }, 3000);

      return () => { clearInterval(intervalId); }
    }
  }, [docId]);

  const docUpdate = async (data) => {
    console.log(data);
    const ref = doc(db, "electrolab", docId);
    await updateDoc(ref, data);
  }

  return (
    <div className="h-screen w-screen" style={{ backgroundImage: `url("${back}")` }}>
      {docId && <select className='left-1/2 fixed -translate-x-1/2  top-40 font-bold text-3xl' onChange={e => {
        docUpdate({ type: e.target.value });
      }}>
        {Object.entries({
          LAB: "LAB", // 緑点灯
          KAIRO: "KAIRO", // 伸びる & 音
        }).map(v => <option value={v[0]}>
          {v[1]}
        </option>)}
      </select>}
      {docId &&
        <div className='rounded-full left-1/2 fixed -translate-x-1/2 top-1/2 -translate-y-1/2 h-32 w-32 bg-red-600 text-white font-bold text-3xl text-center align-middle select-none'
          onTouchStart={() => {
            docUpdate({
              [`pressed_${uid}`]: true
            });
          }}
          onTouchEnd={() => {
            docUpdate({
              [`pressed_${uid}`]: false
            });
          }}
        ><p className='absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 select-none'>PUSH</p></div>
      }
    </div>
  );
}

export default App;
